<template>
  <tr v-if="!!item" :id="rowID">
    <td>{{ item.number }}</td>
    <td v-html="formattedDate"></td>
    <th>
      <span class="text-danger mr-">{{ item.payment || 0 }}</span
      >&nbsp;
      <span>دينار</span>
    </th>
    <td>
      <modal-btn
        btn-class="btn btn-sm block-sm btn-primary mr-md-1"
        :target="`#${updateID}`"
        >تعديل</modal-btn
      >
      <modal-btn
        btn-class="btn btn-sm block-sm btn-soft-danger"
        :target="`#${deleteID}`"
        >حذف</modal-btn
      >
    </td>

    <teleport to="#app">
      <confirm-model
        title="تعديل دفعة"
        :target-id="updateID"
        confirm-text="تعديل"
        @confirmed="clickById(`submit-update-payment-${item.id}`)"
      >
        <form @submit.prevent="update">
          <FormInput
            id="payment"
            type="number"
            text="الدفعة"
            :value="item.payment || 0"
          />
          <button hidden :id="`submit-update-payment-${item.id}`"></button>
        </form>
      </confirm-model>
      <items-destroyer
        title="حذف دفعة"
        :target-id="deleteID"
        :url="`incoming-payments/${item.id}`"
        :row-id="rowID"
        @deleted="deleted"
      >
        <p>هل أنت متأكد من حذف الدفعة؟</p>
        <div class="table-responsive">
          <table class="table table-bordered">
            <tr>
              <th>{{ item.id }}</th>
              <th v-html="formattedDate"></th>
              <th>
                <span class="text-danger">{{ item.payment || 0 }}</span
                >&nbsp;
                <span>دينار</span>
              </th>
            </tr>
          </table>
        </div>
      </items-destroyer>
    </teleport>
  </tr>
</template>

<script>
import date from "@/common/dateMethods";
import ModalBtn from "@/components/UI/ModalBtn";
import ConfirmModel from "@/components/UI/ConfirmModel";
import FormInput from "@/components/Forms/FormInput";
import ItemsDestroyer from "@/components/Common/ItemsDestroyer";

export default {
  components: { ItemsDestroyer, FormInput, ConfirmModel, ModalBtn },
  emits: ["payment-deleted", "payment-updated"],
  props: {
    item: { required: true },
  },
  inject: ["companyId"],
  computed: {
    formattedDate() {
      return !!this.item && !!this.item.created_at
        ? date.withTime(this.item.created_at)
        : "";
    },
    updateID() {
      if (!this.item) return "";

      return `update-payment-modal-${this.item.id}`;
    },
    deleteID() {
      if (!this.item) return "";

      return `delete-payment-modal-${this.item.id}`;
    },
    rowID() {
      if (!this.item) return "";

      return `incoming-payments-row${this.item.id}`;
    },
  },
  methods: {
    async deleted(id) {
      this.$emit("payment-deleted", id);
    },
    async update(e) {
      const formData = new FormData(e.target);
      if (!formData.get("payment"))
        return http.errorMessage("يرجى تحديد الدفعة");

      formData.set("company_id", this.companyId);
      formData.set("_method", "PUT");
      const response = await http.send(
        `incoming-payments/${this.item.id}`,
        formData
      );
      if (http.responseAccepted(response, "payment")) {
        this.$emit("payment-updated", response.data.payment);

        http.successMessage(messages.saved);
        this.hideModal(`#${this.updateID}`);
      }
    },
  },
};
</script>

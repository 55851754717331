<template>
  <div class="container pm-0">
    <div class="row">
      <div class="col-sm-12 d-flex justify-content-start flex-wrap">
        <div>
          <InvoiceFilter
            :with-vehicle="false"
            :with-invoice="false"
            @filters-changed="setFilters"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-end">
        <router-link
          class="btn-sm btn-info rounded-0 mr-1"
          :to="{ name: 'incoming.payments' }"
        >
          <span>العودة</span>&nbsp;
          <i class="fas fa-angle-double-left"></i>
        </router-link>
        <modal-btn btn-class="btn-sm btn-soft-danger" :target="`#${modalID}`">
          إضافة&nbsp;<i class="fa fa-plus"></i>
        </modal-btn>
      </div>
      <div class="col-sm-12">
        <div class="table-responsive">
          <table class="table table-bordered bg-light">
            <tr class="bg-bb">
              <th>رقم الدفعة</th>
              <th>تاريخ الدفعة</th>
              <th>قيمة الدفعة</th>
              <th>تعديل</th>
            </tr>
            <tr v-if="emptyList">
              <td colspan="4" class="font-weight-bolder">لا يوجد مدفوعات</td>
            </tr>
            <PaymentItem
              v-else
              v-for="payment in list"
              :key="payment.id"
              :item="payment"
              @payment-deleted="paymentDeleted"
              @payment-updated="paymentUpdated"
            />
          </table>
        </div>
      </div>
    </div>
    <div class="row" v-if="!emptyList">
      <div class="col-sm-12">
        <ThePaginator
          :total="total"
          :url="defaultUrl"
          :last-page="lastPage"
          :links="links"
          @link-changed="setLink"
        />
      </div>
    </div>
    <teleport to="#app">
      <confirm-model
        title="إضافة دفعة"
        :target-id="modalID"
        confirm-text="إضافة"
        @confirmed="clickById('submit-add-payment')"
      >
        <form @submit.prevent="store">
          <FormInput id="payment" type="number" text="الدفعة" />
          <button hidden id="submit-add-payment"></button>
        </form>
      </confirm-model>
    </teleport>
  </div>
</template>

<script>
import ThePaginator from "@/components/UI/ThePaginator";
import pagination from "@/mixins/pagination";
import InvoiceFilter from "@/components/Invoices/InvoiceFilter";
import PaymentItem from "@/components/Pages/Companies/PaymentItem";
import ModalBtn from "@/components/UI/ModalBtn";
import ConfirmModel from "@/components/UI/ConfirmModel";
import FormInput from "@/components/Forms/FormInput";
import arrays from "@/common/arrays";

export default {
  components: {
    FormInput,
    ConfirmModel,
    ModalBtn,
    PaymentItem,
    InvoiceFilter,
    ThePaginator,
  },
  mixins: [pagination],
  provide() {
    return { companyId: this.companyId };
  },
  data() {
    return { formData: {} };
  },
  computed: {
    defaultUrl() {
      return `incoming-payments/${this.companyId}`;
    },
    companyId() {
      return this.$route.params.company;
    },
    modalID() {
      return "add-payment";
    },
  },
  methods: {
    async store(e) {
      const formData = new FormData(e.target);
      if (!formData.get("payment"))
        return http.errorMessage("يرجى تحديد الدفعة");

      formData.set("company_id", this.companyId);
      const response = await http.send("incoming-payments", formData);
      if (http.responseAccepted(response, "payment")) {
        this.list.push(response.data.payment);

        http.successMessage(messages.saved);
        this.hideModal(`#${this.modalID}`);
      }
    },
    paymentDeleted(paymentId) {
      this.list = arrays.removeById(this.list, paymentId);
    },
    paymentUpdated(payment) {
      this.list = arrays.updateOrPush(this.list, payment);
    },
  },
  async created() {
    await this.setList();
  },
};
</script>
